<!-- giveaway-footer.component.html -->
<div class="giveaway-footer">
  <div class="container-footer">
    <a href="/">
      <img src="assets/Bestinform_logo.png" alt="Bestinform Logo" width="200px">
    </a>
    <div class="menu-items">
        <!-- Contact and Social Media Section -->
        <a class="menu-item" href="/content/giveaway-rules">Regulament concurs</a>

    </div>
  </div>
  <hr class="width">

  <!-- <div class="social-media-icons">
    <a href="https://www.facebook.com/profile.php?id=100076116217462" target="_blank">
      <i class="fab fa-facebook"></i>
    </a>
    <a href="https://instagram.com/bestinform.app?igshid=MzRlODBiNWFlZA==" target="_blank">
      <i class="fab fa-instagram"></i>
    </a>
    <a href="https://www.tiktok.com/@bestinform" target="_blank">
      <i class="fab fa-tiktok"></i>
    </a>
  
  </div> -->
      <!-- Menu Links Section -->
      <div class="menu-links">
        <div class="menu-items">
          <span class="menu-item"  target="_blank">© 2024 Bestinform </span>
          <a class="menu-item" href="/content/privacy-policy" target="_blank">{{ "FOOTER.PRIVACY" | translate }}</a>

        </div>
  
    </div>
  </div>
  