<div class="toaster position-fixed px-3 py-2 m-16">
    <div *ngFor="let toast of currentToasts; index as i">
        <app-toast
                [type]="toast.type"
                [title]="toast.title"
                [message]="toast.message"
                [hidden]="toast.hidden"
                (disposeEvent)="dispose(i)"
        ></app-toast>
    </div>
</div>
