<div class="container">
    <div class="content-404 text-center">
        <div class="img-wrapper">
            <img src="assets/404.png" alt="" class="img-fluid"/>
        </div>
        <h1>Error 404</h1>
        <p>Opps, it seems that this page does not exist.</p>
        <button class="btn-long btn-color-fill" type="button" routerLink="/">Go Home</button>
    </div>
</div>
