<!-- header.component.html -->
<!-- header.component.html -->
<header class="site-header">
  
    <div class="">
      <div class="header-content">
        <div class="logo">
            <img src="../../../../../assets/icons/BestInfoLogo_white.svg" class="not-mobile" alt="">
            <span class="logo-text-white public">Bestinform</span>
        </div>
    </div>
  </div>
  <div class="header-image">
    <div class="image-container">
        <div class="container-text">
            <h1>GIVEAWAY  </h1>
            <p class="text-hero">Petrece o searǎ unicǎ la KONG Herǎstrǎu alǎturi de prietenele tale.<br>
                8 Martie 2024 - București, România</p>
                <div class="container-cards">
                    <div class="container2-cards">
                        <div class="card">
                            <div class="image-card">
                                <img src="../../../../../assets/images/others/2.svg" class="card-icon">
                            </div>
                            <span class="card-text">Nr. Persoane 6</span>
                        </div>
                        <div class="card">
                            <div class="image-card">
                                <img src="../../../../../assets/images/others/3.svg" class="card-icon">
                            </div>
                            <span class="card-text">Cina exclusivista</span>
                        </div>
                        <div class="card">
                            <div class="image-card">
                                <img src="../../../../../assets/icons/cocktail.svg" class="card-icon">
                            </div>
                            <span class="card-text">Șampanie și Tequila incluse</span>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    <div class="header-info">
        <div class="card header-card">
            <div class="image-card">
                <img src="../../../../../assets/images/others/7.svg" class="card-icon icon-header">
            </div>
            <span class="card-text">Înscriere <span class="header-card-text">24 Februarie - 5 Martie 2024</span></span>
        </div>
        <div class="card header-card">
            <div class="image-card">
                <img src="../../../../../assets/images/others/7.svg" class="card-icon icon-header">
            </div>
            <span class="card-text">Extragere <span class="header-card-text">5 Martie 2024</span></span>
        </div>
    </div>
</div>
</header>
