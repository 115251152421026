export const environment = {
  ratehawkSandboxMode:true,
  appVersion: require("../../package.json").version,
  recaptcha: {
    siteKey: "6Ldn1F0oAAAAAGsXgMLrKkl6i-16owTvR8TQ4vXR",
  },
  production: true,
  url: "",
  api_url: "https://bestinform.eu",
  contextPath:'bestinform',
  stripePublicKey: "pk_test_51P3aQlEZiUKGHZHXx0U94QEGhJTKbtRoQTMRmGzoN3nUXT8a0VeeGKDzkwkGbegV6F4OsHs6WyfuCzh4l1vOEY7x00KZdMPGZk"
};
