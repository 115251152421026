import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RegulationCardComponent } from '@standalone-components/regulation-card/regulation-card.component';

@Component({
  selector: 'app-footer-new',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [RegulationCardComponent],
})
export class FooterNewComponent {
  constructor(private router: Router) {}
}
